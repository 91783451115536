//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: #ffac33 !default;
